<!--  -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
          <span style="color: #606266;padding-left: 15px"> 站点名称：</span>
        <el-select v-model="siteid" placeholder="请选择站点">
          <el-option
            v-for="(item,i) in siteList"
            :key="item.id"
            :label="i+1+'  '+item.siteName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <span style="color: #606266;padding-left: 15px"> 序列号：</span>
        <el-input
          placeholder="请输入设备序列号"
          class="search_input"
          v-model="value"
        ></el-input>
        <el-button type="primary" @click="handleSearch(0)">查询</el-button>
        <el-button type="info" @click="handleSearch(1)">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="handleVideo('add')"
        >新增设备
        </el-button
        >
      </div>
    </div>
    <div>
      <el-table
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF',
        }"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column type="index" prop="sid" label="序号" width="95">
        </el-table-column>
        <el-table-column prop="videoName" label="设备名称"></el-table-column>
        <el-table-column prop="siteName" label="所属站点"></el-table-column>
        <el-table-column prop="staName" label="所属泵站"></el-table-column>
        <el-table-column
          prop="gateName"
          label="所属闸门"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column prop="videoType" label="设备类型">
          <template scope="scope">
            {{
              scope.row.videoType == 0
                ? "球机"
                : scope.row.videoType == 1
                  ? "枪机"
                  : "语音"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="videoModel"
          label="设备序列号"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="channelNo"
          label="设备通道"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column width="250" label="操作">
          <template slot-scope="scope">
            <el-button class="edit" @click="handleVideo('edit', scope.row)"
            >编辑
            </el-button
            >
            <el-button class="delete" @click="handleDelete(scope.row)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrent"
        :current-page="pagination.page"
      >
      </el-pagination>
    </div>
    <el-dialog
      class="border-r-8 role-dialog"
      :visible.sync="addShow"
      width="calc(388/1920*100vw)"
      :destroy-on-close="true"
    >
      <div slot="title" class="dialogTitle t-x font-s-18">{{ title }}</div>
      <div class="contentAdd" style="margin-bottom: 10px">
        <el-form :model="videoInfo" ref="ruleForm" :rules="ruleForm">
          <el-form-item
            prop="videoName"
            style="margin-top: calc(25 / 1080 * 100vh)"
          >
            <span class="label_r">设备名称</span>
            <el-input
              v-model="videoInfo.videoName"
              placeholder="请输入设备名称"
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="videoType"
            style="margin-top: calc(30 / 1080 * 100vh)"
          >
            <span class="label_r">视频类型</span>
            <div style="width: calc(244 / 1920 * 100vw)">
              <el-radio v-model="radio" label="1" @change="changeStaId('1')"
              >站点
              </el-radio
              >
              <el-radio v-model="radio" label="2" @change="changeStaId('2')"
              >泵站
              </el-radio
              >
            </div>
          </el-form-item>
          <!-- staId-->
          <template v-if="radio == 1">
            <el-form-item
              prop="siteId"
              style="margin-top: calc(30 / 1080 * 100vh)"
            >
              <span class="label_r">所属站点</span>
              <el-select
                @change="changeValue"
                v-model="videoInfo.siteId"
                placeholder="请选择所属站点"
                readonly
              >
                <el-option
                  v-for="item in stationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              prop="gateId"
              style="margin-top: calc(30 / 1080 * 100vh)"
            >
              <span class="label_r">所属闸门</span>
              <el-select
                v-model="videoInfo.gateId"
                placeholder="请选择所属闸门"
                readonly
              >
                <el-option
                  v-for="item in gateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-if="radio == 2">
            <el-form-item
              prop="staId"
              style="margin-top: calc(30 / 1080 * 100vh)"
            >
              <span class="label_r">所属泵站</span>
              <el-select
                v-model="videoInfo.staId"
                placeholder="请选择所属泵站"
                readonly
              >
                <el-option
                  v-for="item in stationBzList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <el-form-item
            style="margin-top: calc(30 / 1080 * 100vh)"
            v-if="!voiceType"
          >
            <span class="label_r">设备类型</span>
            <el-select
              v-model="videoInfo.videoType"
              placeholder="请选择设备类型"
              readonly
              :clearable="check"
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- <el-input v-model="videoInfo.videoType"></el-input> -->
          </el-form-item>
          <el-form-item
            prop="videoModel"
            style="margin-top: calc(30 / 1080 * 100vh)"
          >
            <span class="label_r">设备序列号</span>
            <el-input
              placeholder="请输入设备序列号"
              maxlength="20"
              v-model="videoInfo.videoModel"
              :readonly="this.title == '视频设备管理-新增' ? false : true"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="channelNo"
            style="margin-top: calc(30 / 1080 * 100vh)"
          >
            <span class="label_r">设备通道</span>
            <el-input
              placeholder="请输入设备通道"
              maxlength="20"
              v-model="videoInfo.channelNo"
              :readonly="this.title == '视频设备管理-新增' ? false : true"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="coordinate" class="mgb">
            <span class="label_r">设备经纬度</span>
            <el-input
              placeholder="请输入经纬度(经度,纬度)"
              v-model="coordinate"
              :readonly="true"
            ></el-input>
          </el-form-item> -->
          <!-- <el-form-item style="height: calc(260 / 1920 * 100vw);">
            <span class="label_r">经纬度选点</span>
            <LocationSelection
              :key="addShow"
              area="东营区"
              :location="coordinate"
              @locationSelection="locationSelection"
              style="width: calc(244 / 1920 * 100vw); height: calc(244 / 1920 * 100vw);"
            />
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleShow" class="guanBi">关闭</el-button>
        <el-button @click="handleSave" type="primary">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {videoAdd, videoEdit, getAllSiteList} from "../../../api/index";
import LocationSelection from "../../../components/LocationSelection/index.vue";
import {getSitesIdList} from "../../../api/main";
import {isValidChannelNo, isValidVideoModel} from "./validate";

var ValidChannelNo = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入容量"));
  } else if (!isValidChannelNo(value)) {
    callback(new Error("请输入正确的容量"));
  } else {
    callback();
  }
};
var ValidVideoModel = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入容量"));
  } else if (!isValidVideoModel(value)) {
    callback(new Error("请输入正确的容量"));
  } else {
    callback();
  }
};
export default {
  components: {
    LocationSelection,
  },
  data() {
    return {
      tableData: [],
      pagination: {
        // 分页
        size: 10,
        page: 1,
        total: 0,
      },
      value: "",
      videoInfo: {
        coordinate: "",
        videoType: 1,
      },
      addShow: false,
      title: "",
      typeList: [
        // 设备类型
        {
          label: "球机",
          value: 0,
        },
        {
          label: "枪机",
          value: 1,
        },
        {
          label: "语音",
          value: 2,
        },
      ],
      stationList: [], // 站点列表
      gateList: [], // 获取闸门列表,
      ruleForm: {
        videoName: [
          {required: true, message: "请输入设备名称", trigger: "blur"},
        ],
        siteId: [
          {required: true, message: "请选择所属站点", trigger: "blur"},
        ],
        videoType: [
          {required: true, message: "请选择设备类型", trigger: "change"},
        ],
        videoModel: [
          {required: true, message: "请输入设备序列号", trigger: "blur"},
          {
            required: true,
            message: "请输入正确的设备序列号",
            trigger: "blur",
            validator: ValidVideoModel,
          },
        ],
        channelNo: [
          {required: true, message: "请输入设备通道", trigger: "blur"},
          {
            required: true,
            message: "请输入正确的设备通道",
            trigger: "blur",
            validator: ValidChannelNo,
          },
        ],
        // gateId: [
        //   { required: true, message: "请选择所属闸门", trigger: "change" }
        // ]
      },
      type: false,
      voiceType: false,
      check: false,
      coordinate: "",
      loading: false,
      radio: "1",
      siteList: [],
      siteid: ''
    };
  },
  computed: {},
  watch: {
    addShow(e) {
      if (!e) this.voiceType = false;
    },
  },
  methods: {
    getList() {
      this.loading = true;
      const {page, size} = this.pagination;
      this.$get(`/video/page/${page}/${size}`)
        .then((res) => {
          this.tableData = res.data.list;
          this.tableData.forEach((item) => {
            if (item.gateId == 0) {
              item.gateName = "公共";
            }
          });
          this.pagination.total = res.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
      // 站点菜单查询
      this.$get("/site/page", {
        page: 1,
        size: 100,
      })
        .then((res) => {
          this.stationList = res.data.list.map((item) => {
            const obj = {
              value: item.id,
              label: item.siteName,
            };
            return obj;
          });
        })
        .finally(() => {
          this.loading = false;
        });
      // stationBzList
      this.$get("/pumpStation/getPumpStations").then((res) => {
        // console.log(res)
        if (res.code == 200) {
          this.stationBzList = res.data.map((item) => {
            return {
              value: item.staId,
              label: item.staName,
            };
          });
        } else {
          this.stationBzList = [];
        }
      });
      getAllSiteList().then(res => {
        console.log(res.data.siteVos);
        this.siteList = res.data.siteVos
      })
    },
    changeStaId(val) {
      // gateId: ""
      // siteId: 74
    },
    // 分页切换
    handleCurrent(page) {
      this.pagination.page = page;
      if (this.value != "") {
        const {page, size} = this.pagination;
        this.$get(`/video/page/${page}/${size}`, {
          videoName: this.value,
        }).then((res) => {
          this.tableData = res.data.list;
          this.pagination.total = res.data.total;
        });
      } else {
        this.getList();
      }
    },
    // 搜索
    handleSearch(type) {
      if (type) {
        this.value = "";
        this.siteid = ''
        this.pagination.page = 1;
        this.getList();
      } else {
        const {page, size} = this.pagination;
        this.$get(`/video/page/${page}/${size}`, {
          siteId: this.siteid,
          videoName: this.value,
        }).then((res) => {
          this.tableData = res.data.list;
          this.pagination.total = res.data.total;
        });
      }
    },
    // 新增编辑
    handleVideo(type, row) {
      console.log(row);
      this.radio = "1";
      this.addShow = true;
      this.videoInfo = {
        coordinate: "",
        videoType: 1,
      };
      this.coordinate = "";

      if (type == "add") {
        this.title = "视频设备管理-新增";
        this.videoInfo = {
          coordinate: "",
          videoType: 1,
        };
        this.videoInfo.videoType = 0;
        this.type = false;
        this.gateList = [];
      } else {
        if (row.staName) {
          this.radio = "2";
        } else {
          this.radio = "1";
        }
        this.title = "视频设备管理--编辑";
        this.type = true;
        this.videoInfo = JSON.parse(JSON.stringify(row));
        console.log(this.videoInfo);
        if (this.videoInfo.videoType === 2) this.voiceType = true;
        getSitesIdList({
          id: this.videoInfo.siteId,
        }).then((res) => {
          this.gateList = res.data.map((item) => {
            const obj = {
              value: item.id,
              label: item.gateName,
            };
            return obj;
          });
          this.gateList.unshift({value: 0, label: "公用"});
        });
        this.coordinate = this.videoInfo.videoX + "," + this.videoInfo.videoY;
      }
    },
    // 新增 编辑
    handleSave() {
      this.$refs.ruleForm.validate((valid) => {
        const {
          id,
          siteId,
          staId,
          videoType,
          videoModel,
          videoName,
          gateId,
          channelNo,
        } = this.videoInfo;
        const arr = this.coordinate.split(",");
        if (valid) {
          if (!(videoType != 1 || (videoType != 0 && siteId))) {
            this.$message({
              message: "请完善信息",
              type: "error",
            });
            return;
          }
          // 编辑
          if (this.type) {
            let obj = {};
            if (this.radio == "1") {
              obj = {
                id,
                siteId,
                videoType,
                videoModel,
                videoName,
                gateId,
                channelNo,
              };
            } else {
              obj = {id, staId, videoType, videoModel, videoName, channelNo};
            }
            videoEdit({
              ...obj,
            })
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    message: res.message,
                    type: "success",
                  });
                  this.addShow = false;
                  this.videoInfo = {
                    coordinate: "",
                    videoType: 1,
                  };
                  this.$refs.ruleForm.resetFields();
                } else {
                  this.$message({
                    message: res.message,
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                this.$message({
                  message: err.message,
                  type: "error",
                });
              })
              .finally(() => {
                // this.coordinate = "";

                this.getList();
              });
          } else {
            let obj = {};
            if (this.radio == "1") {
              obj = {
                siteId,
                videoType,
                videoModel,
                videoName,
                gateId,
                channelNo,
              };
            } else {
              obj = {staId, videoType, videoModel, videoName, channelNo};
            }
            videoAdd({...obj})
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    message: res.message,
                    type: "success",
                  });
                  this.addShow = false;
                  this.videoInfo = {
                    coordinate: "",
                    videoType: 1,
                  };
                  this.$refs.ruleForm.resetFields();
                } else {
                  this.$message({
                    message: res.message,
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                this.$message({
                  message: err.message,
                  type: "error",
                });
              })
              .finally(() => {
                this.getList();
              });
          }
        } else {
        }
      });
    },
    handleShow() {
      this.videoInfo = {
        coordinate: "",
        videoType: 1,
      };
      this.$refs.ruleForm.resetFields();
      this.addShow = false;
    },
    // 删除
    handleDelete(row) {
      this.$confirm("此操作将永久删除该设备名称，确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.$post(`/video/deleteById/${row.id}`)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                if (this.pagination.total % 10 == 1) {
                  this.pagination.page--;
                }
              } else {
                this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.getList();
            });
        })

        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 经纬度选点
    locationSelection(e) {
      if (e.point) {
        this.coordinate = `${e.point.lng},${e.point.lat}`;
      }
    },
    changeValue(value) {
      this.getGateList(value);
    },
    // 获取闸门列表
    getGateList(id) {
      console.log(id);
      if (id !== "") {
        getSitesIdList({
          id: id,
        }).then((res) => {
          if (res.data.length > 0) {
            this.gateList = res.data.map((item) => {
              const obj = {
                value: item.id,
                label: item.gateName,
              };
              return obj;
            });
            this.gateList.unshift({value: 0, label: "公用"});
            this.$set(this.videoInfo, "gateId", this.gateList[0].value);
          } else {
            this.videoInfo.gateId = "";
          }
        });
      }
    },
  },
  created() {
    this.getList();
  },
  mounted() {
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-dialog {
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;

  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;

    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }

  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }

  .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }
}

.el-input,
.el-textarea,
.el-select {
  width: calc(244 / 1920 * 100vw);
}

.images {
  height: calc(60 / 1080 * 100vh);

  i {
    width: calc(59 / 1920 * 100vw);
    height: calc(59 / 1080 * 100vh);
    border: 1px solid #585858;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

::v-deep .el-form-item__content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
}

.label_r {
  margin-left: 20px !important;
  text-align: left;
  color: #666;
  width: calc(85 / 1920 * 100vw);
}

::v-deep .el-input.search_input {
  width: calc(175 / 1920 * 100vw);
}

::v-deep .el-icon-circle-check:before {
  content: "" !important;
}

::v-deep .el-input__icon {
  font-size: calc(10 / 1920 * 100vw) !important;
}
</style>
