// 负责人电话验证
export function isValidChannelNo(str) {
  const reg = /^[0-9]*$/;
  return reg.test(str);
}

export function isValidVideoModel(str) {
  const reg = /^[0-9a-zA-Z]*$/g;
  return reg.test(str);
}
